<template>
    <div v-if="$vuetify.breakpoint.xlOnly" class="product-list-filter">
        <div class="py-20px py-md-30px">
            <filter-brand-categories />
            <v-divider dark />
        </div>
        <div class="py-20px py-md-30px">
            <filter-prices />
        </div>
    </div>

    <div v-else>
        <filter-prices />
    </div>
</template>

<script>
import FilterPrices from "@/components/client/shop/products/filter/filter-prices.vue";
import FilterBrandCategories from "./filter-brand-categories.vue";

export default {
    components: {
        FilterPrices,
        FilterBrandCategories,
    },
};
</script>

<style>
.product-list-filter {
    transform: translateY(-28px);
}
</style>
