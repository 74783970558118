var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: `category-sidebar${_vm.shows ? '' : ' sidebar-hide'}`
  }, [_c('div', {
    staticClass: "category-sidebar__inner"
  }, [_c('nav', {
    staticClass: "product-category"
  }, [_c('div', {
    staticClass: "product-category__inner"
  }, [_vm._m(0), _c('v-divider', {
    staticClass: "grey darken-4 mb-14px",
    staticStyle: {
      "border-width": "3px 0 0"
    }
  }), _c('v-list', {
    staticClass: "product-category-list",
    attrs: {
      "flat": "",
      "no-action": ""
    }
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-list-item', {
      key: item._id,
      attrs: {
        "value": _vm.$route.query.category == item.code
      },
      on: {
        "click": function ($event) {
          return _vm.emit(item);
        }
      }
    }, [_c('v-list-item-title', {
      class: {
        'primary--text': _vm.$route.query.category == item.code
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1)];
  })], 2)], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-10px"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("브랜드전시관")])]);

}]

export { render, staticRenderFns }