<template>
    <v-sheet max-width="768" class="mx-auto">
        <v-row class="row--sm" justify="center">
            <v-col>
                <v-text-field v-model="filter.searchValue" v-bind="{ ...attrs_input_secondary, loading }" label="브랜드를 검색하세요." @keydown.enter="search"/>
            </v-col>
            <v-col cols="auto">
                <v-btn class="v-btn--width-fixed" v-bind="{ ...btn_primary }" @click="search">검색</v-btn>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { attrs_input_secondary, btn_primary } from "@/assets/variables";
export default {
    data:()=>({
        attrs_input_secondary,
        btn_primary,
        loading: false,
        filter: {
            searchKey: "name",
            searchValue: "",
        },
    }),
    computed: {
        params(){
            let { ...query } = this.$route.query;
            // delete query.list;
            if(!query['list']) delete query.list;
            delete query.page;

            return query;
        }
    },
    methods: {
        search() {
            this.$router.push({ query: { ...this.params, ...this.filter } });
        },
    },
}
</script>

<style>

</style>