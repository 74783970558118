var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "768"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "label": "브랜드를 검색하세요."
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search.apply(null, arguments);
      }
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_secondary, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    on: {
      "click": _vm.search
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("검색")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }