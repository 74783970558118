var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual-primary', {
          attrs: {
            "tit": "Brand",
            "bg": "/images/shop/brands/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "path",
            fn: function () {
              return [_c('visual-breadcrumbs')];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "containerSize": "container--lg"
    }
  }, [_c('brand-list-nav'), _c('brand-search-bar'), _c('v-sheet', _vm._b({
    staticClass: "mt-40px mt-lg-60px"
  }, 'v-sheet', {
    loading: _vm.loading
  }, false), [_c('v-row', _vm._l(_vm.items, function (item) {
    var _vm$categories$, _item$thumb;
    return _c('v-col', {
      key: item._id,
      staticClass: "py-10px py-md-20px",
      attrs: {
        "cols": "6",
        "md": "4",
        "lg": "3"
      }
    }, [_c('v-card', {
      attrs: {
        "to": `/shop/brands/${item === null || item === void 0 ? void 0 : item.code}?category=${(_vm$categories$ = _vm.categories[0]) === null || _vm$categories$ === void 0 ? void 0 : _vm$categories$.code}`,
        "tile": ""
      }
    }, [_c('div', {
      staticClass: "square-img border",
      style: `background-image:url("${item === null || item === void 0 ? void 0 : (_item$thumb = item.thumb) === null || _item$thumb === void 0 ? void 0 : _item$thumb.url}");`
    }), _c('div', {
      staticClass: "pt-12px pt-md-14px text-center"
    }, [_c('div', {
      staticClass: "txt txt--dark"
    }, [_vm._v(_vm._s(item.name))]), _c('div', {
      staticClass: "txt txt--sm"
    }, [_vm._v(_vm._s(item.engName))])])])], 1);
  }), 1), _c('v-fade-transition', {
    attrs: {
      "leave-absolute": ""
    }
  }, [_c('v-sheet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "height": "50vh"
    }
  }, [_c('v-layout', {
    attrs: {
      "fill-height": "",
      "column": "",
      "justify-center": "",
      "align-center": ""
    }
  }, [_c('v-card-title', [_vm._v(" 데이터를 불러오고 있습니다")])], 1)], 1)], 1), _c('v-fade-transition', [_c('v-sheet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loading && !_vm.items.length,
      expression: "!loading && !items.length"
    }],
    attrs: {
      "height": "50vh"
    }
  }, [_c('v-layout', {
    attrs: {
      "fill-height": "",
      "column": "",
      "justify-center": "",
      "align-center": ""
    }
  }, [_c('v-card-title', [_vm._v(" 데이터가 없습니다")])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }