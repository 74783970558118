var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tabs-wrap"
  }, [_c('v-btn-toggle', {
    staticClass: "d-md-none",
    model: {
      value: _vm.list,
      callback: function ($$v) {
        _vm.list = $$v;
      },
      expression: "list"
    }
  }, [_c('v-btn', {
    attrs: {
      "to": "/shop/brands",
      "outlined": "",
      "exact": "",
      "tile": ""
    }
  }, [_vm._v("전체")]), _vm._l(_vm.korCharItems, function (value) {
    return _c('v-btn', _vm._b({
      key: value,
      attrs: {
        "id": value,
        "to": '/shop/brands?list=' + value,
        "outlined": "",
        "exact": "",
        "icon": "",
        "tile": ""
      }
    }, 'v-btn', {
      value
    }, false), [_vm._v(_vm._s(value))]);
  }), _vm._l(_vm.engCharItems, function (value) {
    return _c('v-btn', _vm._b({
      key: value,
      attrs: {
        "id": value,
        "to": '/shop/brands?list=' + value,
        "outlined": "",
        "exact": "",
        "icon": "",
        "tile": ""
      }
    }, 'v-btn', {
      value
    }, false), [_vm._v(_vm._s(value))]);
  })], 2), _c('div', {
    staticClass: "d-none d-md-block"
  }, [_c('v-btn-toggle', {
    model: {
      value: _vm.list,
      callback: function ($$v) {
        _vm.list = $$v;
      },
      expression: "list"
    }
  }, [_c('v-btn', {
    attrs: {
      "to": "/shop/brands",
      "outlined": "",
      "exact": "",
      "tile": ""
    }
  }, [_vm._v("전체")]), _vm._l(_vm.korCharItems, function (value) {
    return _c('v-btn', _vm._b({
      key: value,
      attrs: {
        "id": value,
        "to": '/shop/brands?list=' + value,
        "outlined": "",
        "exact": "",
        "icon": "",
        "tile": ""
      }
    }, 'v-btn', {
      value
    }, false), [_vm._v(_vm._s(value))]);
  })], 2), _c('v-btn-toggle', {
    model: {
      value: _vm.list,
      callback: function ($$v) {
        _vm.list = $$v;
      },
      expression: "list"
    }
  }, _vm._l(_vm.engCharItems, function (value) {
    return _c('v-btn', _vm._b({
      key: value,
      attrs: {
        "id": value,
        "to": '/shop/brands?list=' + value,
        "outlined": "",
        "exact": "",
        "icon": "",
        "tile": ""
      }
    }, 'v-btn', {
      value
    }, false), [_vm._v(_vm._s(value))]);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }