<template>
    <div class="tabs-wrap">
        <!-- S: 모바일 탭 -->
        <v-btn-toggle v-model="list" class="d-md-none">
            <v-btn to="/shop/brands" outlined exact tile>전체</v-btn>
            <v-btn v-for="value in korCharItems" :key="value" v-bind="{ value }" :id="value" :to="'/shop/brands?list=' + value" outlined exact icon tile>{{ value }}</v-btn>
            <v-btn v-for="value in engCharItems" :key="value" v-bind="{ value }" :id="value" :to="'/shop/brands?list=' + value" outlined exact icon tile>{{ value }}</v-btn>
        </v-btn-toggle>
        <!-- E: 모바일 탭 -->

        <!-- S: PC 탭 -->
        <div class="d-none d-md-block">
            <v-btn-toggle v-model="list">
                <v-btn to="/shop/brands" outlined exact tile>전체</v-btn>
                <v-btn v-for="value in korCharItems" :key="value" v-bind="{ value }" :id="value" :to="'/shop/brands?list=' + value" outlined exact icon tile>{{ value }}</v-btn>
            </v-btn-toggle>
            <v-btn-toggle v-model="list">
                <v-btn v-for="value in engCharItems" :key="value" v-bind="{ value }" :id="value" :to="'/shop/brands?list=' + value" outlined exact icon tile>{{ value }}</v-btn>
            </v-btn-toggle>
        </div>
        <!-- E: PC 탭 -->
    </div>
</template>

<script>
import api from "@/api";

export default {
    data: () => ({
        list: undefined,
        brands: [],
    }),
    computed: {
        korCharItems() {
            let items = [];
            // null값 제거, 중복제거, 정렬
            this.brands.map((brand) => {
                brand?.searchChar[0] ? items.push(brand?.searchChar[0]) : null;
            });
            items = [...new Set(items)].sort();
            return items;
        },
        engCharItems() {
            let items = [];
            // null값 제거, 중복제거, 정렬
            this.brands.map((brand) => {
                brand?.searchChar[1] ? items.push(brand?.searchChar[1]) : null;
            });
            items = [...new Set(items)].sort();
            return items;
        },
    },
    mounted() {
        this.getChars();
        this.list = this.$route.query.list || null;
    },
    watch: {
        "$route.query.list"(list) {
            this.list = list || null;
        },
    },
    methods: {
        // push() {
        //     const { ...query } = this.$route.query;
        //     query.list = this.list;
        //     if (!query.list) delete query.list;
        //     this.$router.push({ query });
        // },
        async getChars() {
            try {
                const { brands } = await api.v1.shop.brands.getChars();

                this.brands = brands;
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-item-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        .v-btn,
        .v-tab {
            min-width: auto !important;
            height: 50px !important;
            font-size: 14px !important;
            margin: var(--grid-gutter-xxs);
            color: var(--border-color-dark);
            border: 1px solid !important;
            &--active {
                color: #fff;
            }
        }
    }
    .theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
        border-color: var(--v-primary-base) !important;
    }
}
</style>
